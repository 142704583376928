import { useEffect, useRef } from "react";
import { TITLES } from "./Content";
export const videoUrl = "https://pub-2ceb5fd1d48749a5b8ef4628293e3f4c.r2.dev";
export const Home = ({ lang }) => {
  const vidRef = useRef(null);
  useEffect(() => {
    if (vidRef) {
      vidRef.current.play();
    }
  }, []);
  return (
    <div className="page-container">
      <div className="home">
        <div className="column">
          <p className="text about-me">{TITLES[lang].aboutMe}</p>
        </div>
        <div className="column interactive-container">
          <video
            ref={vidRef}
            className="video"
            autoPlay
            muted
            poster={`${videoUrl}/author.png`}
          >
            <source src={`${videoUrl}/portrait.mp4`} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
};
