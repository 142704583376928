export const baseUrl = "https://pub-c0473ab1e9d8486ea1f207a839637df5.r2.dev";

export const buildImageURLs = (folder, count) => {
  const images = [];
  for (let i = 1; i <= count; i++) {
    const imageUrl = `/${folder}/thumbnail_${folder}_${i}.jpg`;
    images.push(`${baseUrl}${imageUrl}`);
  }
  return images;
};
