import { useState } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./App.css";
import { Home } from "./Home";
import { TITLES } from "./Content";
import { Projects } from "./Projects";
import { Contacts } from "./Contacts";
import { allStories, Stories } from "./Stories";
import { Story } from "./stories/StoryPage";
import { getStorySlug } from "./methods";
import { ImageGallery } from "./ImageGallery";
import { buildImageURLs } from "./api/imageService";
import { Evolving } from "./Evolving";

const MAIN_PATH = "/";
export const STORIES_PATH = "/stories";
const PROJECTS_PATH = "/projects";
const CONTACTS_PATH = "/contacts";
const EXHIBITION_PATH = "/exhibition";
const EXPERIMENTS_PATH = "/experiments";

export const CHRISTMAS_PATH = "/christmas";

const NavBar = ({ lang, setLang }) => {
  const location = useLocation();
  const windowWidth = window.innerWidth;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="menu-container">
      <div className="author">{TITLES[lang].author}</div>
      <div className="menu-list">
        {windowWidth < 850 ? (
          <>
            <i className="bi bi-list" onClick={() => setIsOpen(!isOpen)} />
            {isOpen && (
              <div className="menu-burger">
                <Link
                  className={
                    location.pathname === MAIN_PATH
                      ? "route-link active"
                      : "route-link"
                  }
                  to={MAIN_PATH}
                  onClick={() => setIsOpen(false)}
                >
                  {TITLES[lang].about}
                </Link>
                <Link
                  className={
                    location.pathname === STORIES_PATH
                      ? "route-link active"
                      : "route-link"
                  }
                  to={STORIES_PATH}
                  onClick={() => setIsOpen(false)}
                >
                  {TITLES[lang].content}
                </Link>
                <Link
                  className={
                    location.pathname === PROJECTS_PATH
                      ? "route-link active"
                      : "route-link"
                  }
                  to={PROJECTS_PATH}
                  onClick={() => setIsOpen(false)}
                >
                  {TITLES[lang].projects}
                </Link>
                <Link
                  className={
                    location.pathname === EXHIBITION_PATH
                      ? "route-link active"
                      : "route-link"
                  }
                  to={EXHIBITION_PATH}
                  onClick={() => setIsOpen(false)}
                >
                  {TITLES[lang].exhibition}
                </Link>
                <Link
                  className={
                    location.pathname === EXPERIMENTS_PATH
                      ? "route-link active"
                      : "route-link"
                  }
                  to={EXPERIMENTS_PATH}
                  onClick={() => setIsOpen(false)}
                >
                  {TITLES[lang].experiments}
                </Link>
                <Link
                  className={
                    location.pathname === CONTACTS_PATH
                      ? "route-link active"
                      : "route-link"
                  }
                  to={CONTACTS_PATH}
                  onClick={() => setIsOpen(false)}
                >
                  {TITLES[lang].contacts}
                </Link>
              </div>
            )}
          </>
        ) : (
          <div className="menu">
            <Link
              className={
                location.pathname === MAIN_PATH
                  ? "route-link active"
                  : "route-link"
              }
              to={MAIN_PATH}
            >
              {TITLES[lang].about}
            </Link>
            <Link
              className={
                location.pathname === STORIES_PATH
                  ? "route-link active"
                  : "route-link"
              }
              to={STORIES_PATH}
            >
              {TITLES[lang].content}
            </Link>
            <Link
              className={
                location.pathname === PROJECTS_PATH
                  ? "route-link active"
                  : "route-link"
              }
              to={PROJECTS_PATH}
            >
              {TITLES[lang].projects}
            </Link>
            <Link
              className={
                location.pathname === EXHIBITION_PATH
                  ? "route-link active"
                  : "route-link"
              }
              to={EXHIBITION_PATH}
              onClick={() => setIsOpen(false)}
            >
              {TITLES[lang].exhibition}
            </Link>
            <Link
              className={
                location.pathname === EXPERIMENTS_PATH
                  ? "route-link active"
                  : "route-link"
              }
              to={EXPERIMENTS_PATH}
            >
              {TITLES[lang].experiments}
            </Link>
            <Link
              className={
                location.pathname === CONTACTS_PATH
                  ? "route-link active"
                  : "route-link"
              }
              to={CONTACTS_PATH}
            >
              {TITLES[lang].contacts}
            </Link>
          </div>
        )}
        <span
          name="lang"
          className="lang"
          onClick={() => setLang(lang === "UA" ? "EN" : "UA")}
        >
          {lang}
        </span>
        {lang === "UA" ? (
          <i className="bi bi-heart-fill"></i>
        ) : (
          <i className="bi bi-heart"></i>
        )}
      </div>
    </div>
  );
};

function App() {
  const [lang, setLang] = useState("UA");
  const folders = [
    "white",
    "brown",
    "green",
    "yellow",
    "orange",
    "red",
    "pink",
    "purple",
    "blue",
  ];

  const images = folders.map((folder) => buildImageURLs(folder, 15));
  return (
    <div className="App">
      <>
        <NavBar lang={lang} setLang={setLang} />

        <Routes>
          <Route path="/" element={<Home lang={lang} />} />
          <Route path="stories" element={<Stories lang={lang} />} />
          <Route path="projects" element={<Projects />} />
          <Route path="contacts" element={<Contacts lang={lang} />} />
          <Route
            path="exhibition"
            element={<ImageGallery images={images} columns={5} rows={3} />}
          />
          <Route path="experiments" element={<Evolving />} />
          {allStories.map((story, i) => (
            <Route
              path={getStorySlug(story["EN"].header)}
              element={<Story story={story[lang]} key={i} />}
              key={i}
            />
          ))}
        </Routes>
        <footer className="footer">
          {/* <a href="https://t.me/yuliiasugak">
            <i className="bi bi-telegram"></i>
          </a> */}
          <a href="https://www.instagram.com/vorona_yulia">
            <span className="circle">
              <i className="bi bi-instagram"></i>
            </span>
          </a>
          <a href="https://www.facebook.com/juju.vorona">
            <i className="bi bi-facebook"></i>
          </a>
        </footer>
      </>
    </div>
  );
}

export default App;
