export const TITLES = {
  EN: {
    about: "About me",
    content: "Stories",
    projects: "Pet-projects",
    exhibition: "Exhibition",
    contacts: "Contacts",
    aboutMe: `My creativity manifests in various forms — I capture the vibrant moments of life through photography, create light and atmospheric watercolors, write stories, or write code to bring my new ideas to life. 
    Each part of my life adds depth and color to my personality. 
    I am constantly learning, growing, and embracing new challenges, combining my technical skills with artistic ones to live life to the fullest. 
    Thank you for visiting!`,
    author: "Yuliia Sugak",
    contactsText: "Please contact me via email - jujuvorona@gmail.com",
    contentTitle: "Content",
    experiments: "Experiments",
  },
  UA: {
    about: "Про мене",
    content: "Оповідання",
    projects: "Pet-проекти",
    exhibition: "Виставка",
    contacts: "Контакти",
    aboutMe: `Моя творчість проявляється у різних формах - я ловлю яскраві моменти життя через фотографію, створюю легкі та атмосферні акварелі, пишу оповідання, чи пишу код та втілюю свої нові ідеї.
    Кожна частина мого життя додає глибини та кольору моїй особистості. Я постійно вчуся, зростаю і приймаю нові виклики, поєднуючи свої технічні навички з мистецькими, щоб жити наповну.
     Дякую, що завітали!`,
    author: "Юлія Сугак",
    contactsText: "Моя пошта для зворотнього зв'язку - jujuvorona@gmail.com",
    contentTitle: "Зміст",
    experiments: "Експерименти",
  },
};
