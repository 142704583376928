import { baseUrl } from "./api/imageService";

export const Evolving = ({ lang }) => {
  return (
    <div className="page-container">
      <div>
        <video className="experiments-video" autoPlay muted loop>
          <source src={`${baseUrl}/creative/white_6.MP4`} type="video/mp4" />
        </video>
        <video className="experiments-video" autoPlay muted loop>
          <source src={`${baseUrl}/creative/creative.MP4`} type="video/mp4" />
        </video>
        <video className="experiments-video" autoPlay muted loop>
          <source src={`${baseUrl}/creative/pink.mp4`} type="video/mp4" />
        </video>
        <video className="experiments-video" autoPlay muted loop>
          <source src={`${baseUrl}/creative/tulips.MP4`} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};
