export const Story4 = {
  EN: {
    header: "Magical Monday",
    content: `Monday seemed like any other day. After a sunny weekend, countless conversations, messages, and complex decisions inundated her. It was nearly ten, and Ann was still at work. For over a year, she had keenly felt all the symptoms of burnout. Every Friday, she promised to take an extended vacation for a month or two. And every darn Monday, she opened her laptop and sorted through her email again.

She honestly and desperately loved her job as a sales manager: successful deals, and cool business trips to different parts of the world. But last year, everything went downhill. The coronavirus, with its restrictive measures, turned the whole world upside down. Something was always exploding at work, and their team had to extinguish fires quickly. The constant stress was exhausting. She didn't feel like doing anything. Life passed her by, and she stood aside silently watching.

Today she needed to proofread an important contract, and she had been staring at the monitor and clicking her mouse on photos of acquaintances for over two hours. Every one had families, children, cats, dogs, and birthday cakes with candles. What she had never particularly desired now caused her incredible envy. Even those steep bouquets of tulips on March 8th.

The girl opened her email and quickly wrote a letter to her manager, so he could read in the morning. It's time to run. Finally, that magical Monday had arrived!

The guard locked the door behind her as she was the last to leave the office. It started snowing outside. Ann got into her car turned on the music, but immediately turned it off. The mood was not right, she wanted to dive into her feelings and thoughts. The girl took several deep breaths, trying to calm her racing heart. Within minutes, Ann was racing through the city at night. She didn't need to go home, she had her passport. Without traffic, she would reach the airport in an hour.
"Am I doing this?” echoed in her head. “Oh, my God! What will happen? What will happen!"

She pulled into the paid parking lot where she usually left her car when flying somewhere. She should have gone home and taken some things with her. But she was afraid that the excitement and freedom would fade, and in the morning, she would be back at the office. Lost in her thoughts, she approached the terminal doors, where the security guard immediately brought her back to reality.

"Your ticket, please."
"I don't have one yet, I'm going to the ticket office to buy one."
"Unfortunately, we can't let you in without a ticket. You need a ticket to enter the terminal."
"Can't I buy it at the ticket office?"
"You can buy it online only."

The girl disappointedly stepped away from the passage. That's how it is with spontaneity. She already had a perfect plan in her head to escape with adventures. She wondered how surprised the cashier would be that she wants to buy a ticket blindly. “Is it possible?” Ann pulled out her phone, thinking about who to ask to get her a ticket at almost midnight. Google is the best advisor in such matters, she typed in "Travel Blindly".
To her surprise, the very first headline revealed that some charter airline offers this service 24/7!

"This journey will change your life forever!"
"Jackpot!" exclaimed Ann and  quickly sent a message to the bot's number and received an immediate response. The operator asked her to download an app where she could review the terms and conditions and sign with a fingerprint scan. She quickly skimmed through the contract and scanned her fingerprint, agreeing to all the terms. The operator warned her that processing the order could take up to ten minutes. These were the longest minutes of her life.

For the first two minutes, Ann stared at her phone without blinking, hoping the ticket would arrive earlier. Between the third and fifth minute, she felt very naive and began to worry that she had just been scammed out of her money. After waiting five minutes, she scolded herself for making such a foolish and infantile decision. From the seventh to the eighth minute, she calmed down and dreamed that her trip would take her to a warm and exotic country. And finally, her phone chimed in her pocket on the ninth minute. The ticket had arrived! Overjoyed, she jumped up and immediately headed to the security guard, who let her through to the warm terminal.

There were many people in the airport despite the quarantine. A new message from the operator appeared on her screen:
"Boarding for your flight has already begun. Your terminal is G."

"Which terminal is that? There's no terminal like that in this airport. I travel all the time, and I've never even heard of it," she quickly typed out the message.

"It's new. Open our app, and you will receive an interactive map with hints on how to get there."

"Wow, cool! Thank you!"
Ann pulled out her phone, a cute little white kitten appeared on the screen, meowing and running forward. The girl followed the kitten to the mysterious new terminal. It was a good thing she didn't have any luggage with her because the route was like an actual quest. Thanks to the kitten, she could still check in just in time. Two more floors and she was already in the boarding queue!

The flight attendant greeted her with a smile and said hello in English. So, the crew was not Ukrainian. Surprisingly, the cabin was quiet; all the passengers sat through one empty seat in the middle. No one argued about whose luggage would be stored where or asked to switch seats. There weren't even any children. Ann quickly walked to her seat in the tail section.
The pilot's pleasant voice rang out, welcoming them onboard the aircraft. He warned them to take their seats, fasten their seat belts, and prepare for a stunning journey that would change their lives forever.

"The marketing level is off the charts. A journey that will change my life forever!" the girl thought with a smile. She wondered if any other passengers bought tickets blindly.

"Do you know where this plane is flying to?" she asked her neighbor nervously.
The guy smiled and shrugged. "To be honest, I have no idea. I bought a blind travel ticket."
"No way!"Ann exclaimed in surprise. "Me too! Why did you decide to do that?"
"Well, I just saw an advertisement on the internet and decided to try it out."
"I don't know how you're holding up, but I'm already so curious about where I'm flying to."
"Real adventures!"
"Yes!"

Adrenaline was gradually decreasing, and Ann felt tired. As soon as the lights in the airplane cabin dimmed, she fell asleep.

It seemed like her eyes had closed for just a minute when bright light had already sneaked under her eyelids. The girl didn't immediately understand where she was and what was happening. Only after a few seconds did she remember all the previous night's events. They were still flying. No way! Over the ocean! The neighbor who sat by the window disappeared somewhere, and Ann, taking the opportunity, peered out the window. Through white clouds, endless blue water shone. She decided to go to the bathroom, but the neighbor was probably there. How could he slip by her without waking her up?! After waiting for a few minutes, she still decided to go and explore. The toilet turned out to be free!

After finishing all her business, the girl went out into the corridor. Ann was still very sleepy, but she felt that something was wrong. It was way too quiet in the airplane. The girl looked around and realized that not only had her neighbor disappeared, but all the other passengers as well. How could this be? She walked forward slowly. Indeed, all the seats were empty. There were no flight attendants either. Her heart began to beat faster and was beating a precise rhythm in her plugged ears. She had to go to the cockpit; someone must be there because the plane was flying. With trembling hands, she knocked, but no one answered. The girl opened the door and nearly fainted. No one there; the plane was flying on autopilot. Ann froze and slowly shifted her gaze from one gauge to another. She wanted to close her eyes and wake up.

"I'm sleeping," she whispered. "This is a dream. I need to wake up."

Ann closed her eyes, opened them, and closed them again. It lasted for several minutes, after which she began to pinch herself, but it didn't help either. The girl returned to the cabin and sat in one of the chairs.

“The plane is flying at a crazy speed over the ocean, and there's only me on board, with no idea what to do. How could this be happening? It's impossible! So I don't need to do anything. This must be a dream. I'm a psychologically healthy and normal person. This couldn't be happening. Pilots don't abandon planes with passengers. This is just a dream, and I'll wait until I wake up. I may need to go to sleep now. Yes, that's it. I'll sleep.”

Ann settled comfortably in her seat and closed her eyes. She tried with all her might to fall asleep, but she was nervous and kept opening her eyes over and over again. Nothing changed. After a while, she finally realized she wasn't sleeping and burst into tears as hard as possible. She needed to return to the cockpit. But why? She had no idea what to do.

Then the girl remembered the mobile app she had downloaded at the airport and immediately pulled out her phone. Not knowing what she was looking for, she clicked on every menu item with no connection or internet, until her gaze caught the subheading "Detailed instructions on how to fly a plane."

"What?!" she exclaimed angrily. "Is this a joke?! This is impossible! Impossible! Hey! Can anyone hear me? Stop this now!"

She screamed as loudly as she could, but no one responded.
`,
  },
  UA: {
    header: "Магічний понеділок",
    content: `Понеділок видався як завжди як у всіх. Незліченні розмови, переписки, складні рішення лилися наче злива після сонячних вихідних. Майже десята вечора, а Аня все ще сиділа на роботі. Більше року вона гостро відчувала всі симптоми вигорання. Кожну п’ятницю обіцяла собі взяти довгу відпустку на місяць-два. І кожного довбаного понеділка знову відкривала свій ноут і розгрібала пошту.

    Вона чесно та відчайдушно любила свою роботу сейлз менеджера: успішні угоди, класні бізнес-подорожі в різні куточки світу. Але минулого року все полетіло шкереберть. Коронавірус зі своїми обмежувальними заходами перевернув цілий світ. На роботі постійно щось вибухало то тут, то там, а їхній команді доводилось швидко гасити пожежі. Постійний стрес виснажував. Нічого не хотілось. Життя проносилося повз неї, а вона ніби стояла осторонь і мовчки спостерігала.
    
    Сьогодні їй потрібно було вичитати важливий договір, а вона вже більше двох годин просто дивиться в монітор і клацає мишкою по фотографіях знайомих. У всіх сім'ї, діти, кішки, собаки, торти зі свічками на дні народження. Те, до чого вона ніколи особливо не прагнула, зараз викликало в неї неймовірні заздрощі. Навіть ті стрьомні букети тюльпанів на Восьме Березня.
    
    Дівчина відкрила пошту і швидко написала листа своєму менеджерові, щоб прочитав вранці. Час бігти. Нарешті, настав той самий магічний понеділок!
    
    Охоронець закрив за нею двері, вона остання покидала офіс. На вулиці почав падати сніг. Аня сіла в машину, увімкнула музику, але одразу ж вимкнула. Настрій був не той, захотілося поринути у свої почуття та думки. Дівчина зробила кілька глибоких вдихів і видихів, намагаючись заспокоїти серце, що так калатало. Уже через кілька хвилин Аня мчала по нічному місту. Додому можна не їхати, паспорт із собою. Без заторів вона дістанеться до аеропорту за годину.
    "Невже я справді це роблю? – лунало в голові. – Ох, нічого собі! Що буде? Що буде!”
    
    Вона заїхала на платну зупинку, де зазвичай залишала машину, коли летіла кудись. Може й треба було б поїхати додому та взяти хоч якісь речі. Але вона боялась, що це відчуття драйву та свободи мине, і вранці вона знову повернеться в офіс. У вирі своїх думок вона підійшла до дверей терміналу, де охоронець одразу ж повернув її на землю.
    
    – Ваш квиток, будь ласка.
    – А в мене ще немає, я йду до каси, щоб купити.
    – На жаль, ми не можемо вас пропустити. Для входу в термінал потрібен квиток.
    – Я не можу його купити в касі?
    – Виходить, що так.
    
    Дівчина розчаровано відійшла з проходу. Ось так і будь спонтанною. У голові вже з’явився ідеальний план втечі з пригодами. А як здивувався б касир, що вона хоче купити білет наосліп. Цікаво, чи взагалі можна так? Аня дістала телефон, розмірковуючи, кого ж попросити взяти їй квиток майже опівночі. Гугл – найкращий радник у таких справах, вона ввела в пошук “Мандрівка наосліп”.
    На превеликий подив, перший же заголовок розповідав, що якась чартерна авіакомпанія дійсно пропонує таку послугу 24/7!
    
    “Ця подорож змінить ваше життя назавжди!”
    
    Джекпот! Аня швидко відправила повідомлення на номер бота і в ту ж мить отримала відповідь. Оператор попросила її завантажити додаток, де вона мала ознайомитися з умовами та поставити підпис відбитком пальця. Вона швидко пробігла очима по договору і відсканувала свій відбиток пальця, погоджуючись з усіма умовами. Оператор одразу попередила, що оформлення замовлення може зайняти до десяти хвилин. Це були найдовші хвилини в її житті.
    
    Перші дві хвилини Аня, не блимаючи, дивилась у телефон, раптом квиток прийде раніше. Між третьою і п’ятою – відчула себе дуже наївною і почала боятися, що її просто кинуть на гроші. Після п’яти хвилин очікування уже повним ходом картала себе за таке, насправді, дуркувате і дуже інфантильне рішення. А від сьомої до восьмої хвилини заспокоїлась і мріяла, щоб мандрівка випала на якусь теплу та екзотичну країну. І ось на дев’ятій хвилині телефон нарешті телінькнув у кишені. Прийшов квиток! Яке щастя! Він прийшов! Підстрибнувши на місці, вона одразу ж помчала до охоронця, і він пустив її до теплого терміналу.
    
    В аеропорті було чимало людей, незважаючи на карантин. На екрані з’явилось нове повідомлення від оператора:
    – Посадка на ваш рейс уже почалась, у вас термінал G.
    – А що це за термінал? У Борисполі немає такого. Я постійно літаю, навіть не чула, – швидко набирала вона повідомлення.
    – Це новий. Відкрийте наш додаток і отримаєте інтерактивну карту з підказками, як до нього пройти.
    – Ого, круто! Дякую!
    
    Аня дістала телефон, на екрані з’явилася мила біленька киця, яка замурчала та побігла вперед. Дівчина швидко пішла за кішкою до загадкового нового терміналу. Добре, що в неї не було з собою валіз, бо маршрут нагадував справжній квест. Дивина та й годі. Дякуючи киці, вона все ж таки встигла останньою на реєстрацію. Далі ще два поверхи – і ось вона вже в черзі на посадку!
    
    Бортпровідниця привітно посміхнулася і привіталася англійською мовою. Отже, екіпаж не український. У салоні, на диво, панувала тиша, усі пасажири сиділи через одне порожнє місце посередині. Ніхто не сварився, де чия валіза буде стояти; не просив помінятися місцями; навіть діти не галасували. Дітей, до речі, взагалі не було. Аня швидко пройшла до свого місця в хвості.
    Зазвучав приємний голос пілота, який вітав їх на борту літака, він попередив, щоб усі зайняли свої місця, пристебнули ремені безпеки та приготувалися до приголомшливої подорожі, що змінить їхнє життя назавжди.
    “Рівень маркетингу зашкалює. Подорож, що змінить моє життя назавжди!” – з посмішкою подумала дівчина. Стало цікаво, чи є ще такі пасажири, хто купив квиток наосліп.
    
    – А ви не підкажете, куди летить цей літак? – трохи ніяково запитала вона у свого сусіда.
    – Хлопець посміхнувся і стиснув плечима:
    – Чесно кажучи, гадки не маю. Я купив мандрівку наосліп.
    – Нічого собі! – здивовано вигукнула Аня. Я також! А чому так вирішили?
    – Та просто, – посміхнувся хлопець. – Побачив рекламу десь в інтернеті і вирішив спробувати.
    – Не знаю, як ви тримаєтесь, мені вже так цікаво, куди я лечу.
    – Справжні пригоди!
    – Так!
    
    Адреналін потроху знижувався, і Аня відчула втому. Як тільки в салоні літака приглушили світло, вона заснула.
    Здалося, що очі закрилися буквально на хвилинку, коли яскраве світло вже проникло під повіки. Дівчина не одразу зрозуміла, де вона, і що відбувається. Лише через кілька секунд пригадала всі події минулої ночі. Вони все ще летіли. Нічого собі! Через океан! Сусід, що сидів біля вікна, кудись зник, і Аня, скориставшись можливістю, зазирнула у вікно. Крізь білі хмари світилась блакитна безмежна вода. Їй одразу ж захотілося в туалет, але, мабуть, сусід саме там і був. Як він зміг так прослизнути повз неї, щоб не розбудити?! Почекавши хвилин п’ять, вона все ж таки вирішила піти на розвідку. Туалет виявився вільним!
    
    Зробивши всі свої справи, дівчина вийшла в коридор. Аня ще була дуже сонна, та все одно відчула, що щось не так. У літаку стало аж занадто тихо. Дівчина озирнулась навкруги і лише тепер зрозуміла, що не тільки її сусід кудись пропав, але й усі інші пасажири. Як таке може бути? Вона повільно пройшла вперед. Дійсно, усі сидіння були порожні. Не було навіть бортпровідників. Серце почало калатати та відбивати чіткий ритм у закладених вухах. Треба зайти до кабіни пілотів, там точно хтось повинен бути, адже літак летить. Тремтячою рукою вона постукала, ніхто не відповів. Дівчина відкрила двері і ледь не зомліла. У кабіні теж нікого не було, літак летів на автопілоті. Аня завмерла і повільно переводила погляд з одного датчика на другий. Захотілось закрити очі і прокинутись.
    
    – Я сплю, – прошепотіла вона. – Це сон. Треба прокинутись.
    
    Аня замружила очі. Відкрила і знову замружила. Так тривало кілька хвилин, після чого вона почала себе щипати, це теж не допомагало. Дівчина повернулась у салон і сіла в одне з крісел.
    
    – Літак летить з шаленою швидкістю над океаном, на борту лише я, і гадки не маю, що робити. Як таке може бути? Ніяк! Тож мені не треба нічого робити. Це точно сон. Я – психологічно здорова людина, адекватна. Таке не могло трапитись. Пілоти не покидають літак разом з пасажирами. Це просто сон, і я почекаю, поки прокинусь. Можливо, мені треба заснути зараз. Саме так. Буду спати.
    
    Аня зручно вмостилась у кріслі і закрила очі. З усіх сил вона намагалась заснути, але нервувала і постійно відкривала очі. Знову й знову. Нічого не змінювалось. Через деякий час вона нарешті зрозуміла, що не спить і розплакалась так сильно, на скільки лише була здатна. Треба повернутись у кабіну пілотів. Але навіщо? Вона геть нічого в цьому не розуміла.
    
    Тут дівчина згадала про мобільний додаток, який скачала в аеропорті, і одразу ж дістала телефон. Не розуміючи, що саме шукає, зв’язку та інтернету не було, просто клацала на кожен пункт меню, аж поки погляд зачепився за підзаголовок “Детальні інструкції, як керувати літаком”.
    – Що?! – гнівно видихнула вона. – Це що жарт?! Це неможливо! Неможливо! Агов! Мене хтось чує? Негайно припиніть це все!
    
    Вона кричала так голосно, як тільки вистачало сил, та ніхто не озивався.`,
  },
};
