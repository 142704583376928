export const Projects = () => {
  return (
    <div className="page-container ">
      <div className="projects">
        <div className="row-container">
          <div>
            <a href="https://marketplace.visualstudio.com/items?itemName=Yulia.motivator#review-details">
              <span className="bold">Motivator</span>
              <img
                className="logo"
                src={require("./images/Motivator_logo.png")}
                alt="logo"
              />
            </a>
          </div>
          <span className="text">
            Fun extension that supports you in everyday work
          </span>
        </div>
        <div className="row-container">
          <div>
            <a href="https://kush.com.ua/">
              <span className="bold">Великий Куш</span>
              <img
                className="logo"
                src={require("./images/Kush_logo.jpeg")}
                alt="logo"
              />
            </a>
          </div>
          <span className="text">
            Free price monitoring tool for the Ukrainian largest marketplace
            (the project is closed)
          </span>
        </div>

        <div className="row-container">
          <div>
            <a href="https://mycoolcv.com/">
              <span className="bold">My cool CV</span>
              <img
                className="logo"
                src={require("./images/MyCoolCV_logo.png")}
                alt="logo"
              />
            </a>
          </div>
          <span className="text">Free WYSIWYG app for creating CVs</span>
        </div>
      </div>
    </div>
  );
};
