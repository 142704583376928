export const Story1 = {
  EN: {
    header: "Thousand and One",
    content: `The dawn had just touched the sky. The day promised to be pleasant and warm. Spring was always magical here: the plateau was covered with soft grass and flowers. Due to the increased humidity, all the trees were covered with light moss. Enormous birds soared through the sky, and further in the forests, there lived animals that were impossible to see elsewhere. Planet Mao remained the only oasis in the distant galaxy of Ro. The neighboring planets were rapidly developing, waging wars, and joining alliances. Only Planet Mao remained apart. Here, harmony and tranquility reigned. Perhaps that's why the inhabitants of the planet were so different from other nations, and this frightened people. Just as light cannot exist without shadows, so silence cannot exist without thunder.
    
    Kyoto sat on the floor in the hallway, silently watching as dark figures lined up a hundred meters from his home. There was no trace of fear on his face. Kyoto had felt this threat for a long time. He breathed evenly. A proud heir of a proud nation. His hand today, like his father's hand once, would not tremble. The sword would be swift and cold. Today is Victory Day. All the planet's inhabitants, except for Kyoto, were still asleep. Today they should wake up later so that nothing would disturb their existence. Planet Mao was a huge ecosystem, and the Maotians were a part of it. Everyone played their role, without which their coexistence would have been impossible.
    
    The dark formation slowly began to move. Kyoto also stood up and approached the wall, the steel of his sword gleaming in the morning sun. He took out a silk scarf and tossed it into the air. In a matter of seconds, several pieces fell to the floor. Another twist of the wrist, another sword movement, and the whole scarf was back in his hand. Kyoto wiped his sword. No matter how long the peace lasted, the blade was always sharp. Darkness approached. Like bees from a large hive, individual shadows began to separate. A horn rang out, sounding the call to battle readiness. With the speed of light, Kyoto was already outside. Thousands of eyes watched him with curiosity. They were afraid. Fear dripped cold sweat down their foreheads.
    
    "Welcome, Kyoto, son of the Great Vakha!" the commanding officer said.
    
    Kyoto remained silent, and his silence echoed back from everything on the other side. He never took his eyes off the enemy for a second, his breathing slowed down, and with it time slowed down too. Kyoto was a warrior and defender of the planet Mao. His craft was passed down from generation to generation, just as the sky was the sky, the grass was the grass, and the river was the river, from birth to death.
    
    The central rank parted, and Kyoto saw giant machines from which the commanding officer's voice sounded loud and confident. 

    "We came in peace," he said.
    
    From childhood, his father taught him that whoever comes in peace doesn't always leave in peace. 

    "We brought gifts to you," the commanding officer continued.
    
    Kyoto knew that he can own only his sword.
    
    The wind passed through the ranks, the shadows swayed like stems in a field. Suddenly, he saw a pale light. Kyoto immediately recognized an innocent soul that hesitated. Could she save herself? Just a moment - and Kyoto looked into her. He felt the aroma of ripe peaches that a little boy picks from the branches. Sticky sweet juice flows over Kyoto's hands. His beautiful mother calls him. Kyoto recognizes the locality. It is an old settlement in the east of the planet Mao. The cold mountain river washes dirty feet, and he hears the ringing laughter of a girl. She has long white hair, tangled by the wind, with flowers and grass sticking out of it.
    
    "Olivia, hi!"
    "Hi there!"
    "My mom and I are flying home tomorrow."
    "Oh, so soon? I'll miss you."
    "Don't be sad, I'll come back!"
    "Really? Promise?"
    "Yes, I promise!"
    
    Suddenly, pain and shame engulfed him like a huge wave, washing away all memories. It would be better if he didn't come back.
    
    "Kyoto is the son of the Great Vakha. The planet Mao has significant potential for us, and we would like to offer you, as the heir to the ancient lineage, cooperation. Your planet is small and therefore weak. Look at our power." The darkness began to thicken, and even black clouds appeared out of nowhere in the sky. Kyoto looked around, but he could not find that pale soul. She had disappeared into the crowd.
    
    "Real power cannot be seen," Kyoto said quietly. "It cannot be measured by the number of weapons, machines, or bodies. Everyone has their own strength."
    
    "Consider our offer."
    
    "Go in peace," Kyoto replied firmly.

    The horn sounded again. This was the signal for the attack. Kyoto prepared himself; the battle would not last longer than a hundred heartbeats.
    
    Ninety-seven. Ninety-eight. Ninety-nine. Kyoto stopped and lowered his sword. The ground beneath his feet was shrouded in shadows. Kyoto knew they would return. But the Warrior would win again, because the only battle he could lose was the battle with himself. He was at peace inside. He took out a silk scarf and wiped the sword, which was covered with whimsical patterns of red spots.
    Kyoto closed his eyes and listened. The birds began to sing again, the roar of animals came from the depths of the forest, and the water murmured softly in the streams. He turned and looked at his house, where Akaya slept, unaware of anything. Kyoto did not mourn the fallen on this battlefield, and those who would still have to graze here. They were not warriors, just shadows. The wind carried the scent of their blood and the echo of their last breath. No one will remember the inglorious names, and no one will cry for them. In a few minutes, only soft green grass was around him again. The planet Mao had accepted their sacrifice. Kyoto put the snow-white scarf in his pocket and walked back to the house.`,
  },
  UA: {
    header: "Тисяча і один",
    content: `Світанок ледь торкнувся неба. День обіцяв бути погожим і теплим. Навесні тут завжди було чарівно: плато покривалося м'якою травою та квітами. Через підвищену вологість усі дерева були оповиті легким мохом. У повітрі літали величезні птахи, там далі, у лісах, мешкали тварини, яких уже неможливо побачити деінде. Планета Мао залишилась єдиним оазисом далекої галактики Ро. Сусідні планети стрімко розвивались, вели війни, вступали до альянсів. Лише планета Мао залишалась осторонь. Тут панували гармонія та спокій. Можливо, тому жителі планети так сильно відрізнялись від інших народів, і це лякало. Як світло не може існувати без тіні, так і тиша — без грому.

    Кіото сидів на підлозі в передпокої та мовчки спостерігав, як в ста метрах від його дому шикувалися темні постаті. На його обличчі не було й сліду страху. Кіото давно відчував цю загрозу. Він дихав рівно. Гордий спадкоємець гордого народу. Його рука сьогодні, як колись рука його батька, не здригнеться. Меч буде швидким і холодним. Сьогодні День Перемоги. Всі жителі планети, окрім Кіото, ще спали. Сьогодні вони мають прокинутись пізніше, щоб ніщо не тривожило їхнє буття. Планета Мао являла собою величезну екосистему, і маотяни були її частиною. Кожен виконував свою роль, без цього їхнє спільне існування стало б неможливим.
    
    Темна шеренга повільно рушила. Кіото теж встав і підійшов до стіни, сталь меча виблискувала в променях ранкового сонця. Він дістав шовкову хустку та підкинув її вгору. Долі секунди вистачило, щоб на підлогу опустилося кілька клаптів. Ще один поворот зап’ястя, ще один рух мечем – і ціла хустка опинилася знову в його руці. Кіото протер меч. Як довго не тривав би мир, та лезо завжди було гострим.Темрява наближалась. Немов бджоли з великого вулика, почали відокремлюватися поодинокі тіні. Пролунав звук рогу. Так сурмили про готовність до наступу. Зі швидкістю світла Кіото вже був зовні. Тисячі очей з цікавістю дивилися на нього. Вони боялися. Страх виступав холодним потом на їхніх чолах.
    
    – Вітаємо тебе, Кіото, син Великого Вакха!
    Кіото мовчав, і його мовчання луною відбивалось від кожного по той бік. Він не зводив очей з ворога ні на секунду, його дихання сповільнилось, а разом із ним і час. Кіото був Воїном, захисником планети Мао. Його ремесло передавалось із покоління в покоління. Як небо було небом, як трава була травою, як річка було рікою, так і він був Воїном. Від самого народження до самої смерті.
    Центральна шеренга розступилась, Кіото побачив велетенські машини, з яких сильно та впевнено лунав голос головнокомандувача:
    – Ми прийшли з миром.
    
    Ще змалку батько навчив його, що той, хто приходить з миром, не завжди йде з миром.
    
    – Ми принесли тобі дари.
    
    Кіото знав, що володіти можна лише собою та своїм мечем.
    
    Вітер пройшов поміж шеренг, тіні захитались, ніби колоски в полі. Раптом він побачив бліде світло. Кіото одразу впізнав невинну душу, що вагалася. Чи зможе вона врятуватися? Лише мить – і Кіото зазирнув в неї. Він відчув аромат стиглих персиків, які маленький хлопчик зриває з гілок. Липкий солодкий сік тече по його руках. Він з насолодою їсть і вже обдумує, який персик буде наступним. Його кличе мати-красуня. Кіото впізнає місцевість. Це старе поселення на сході планети Мао. Холодна гірська річка омиває брудні ноги, він чує дзвінкий сміх дівчинки. У неї біле довге волосся, спутане від вітру, з нього стирчать квіти і трави.
    
    – Олівія, привіт!
    – Привіт!
    – Ми завтра з мамою вже відлітаємо додому.
    – Як шкода, я буду сумувати.
    – Не сумуй, я повернусь!
    – Обіцяєш?
    – Обіцяю!
    
    Біль і сором, ніби величезна хвиля, накрили з головою, змиваючи усі спогади. Краще б він не повертався.
    
    – Кіото – син Великого Вакха. Планета Мао має значний потенціал для нас. І ми хочемо запропонувати тобі, як спадкоємцю стародавнього роду, співпрацю. Твоя планета маленька, а значить слабка. Подивись на нашу силу.
    Темрява почала згущатись. Навіть на небі з’явились нізвідки чорні хмари. Кіото знову пройшовся очима по тінях і вже не знайшов тієї блідої душі. Вона розчинилася в натовпі.
    – Справжню силу неможливо побачити, – тихо промовив Кіото. – Її неможливо виміряти кількістю зброї, машин чи тіл. Сила у кожного своя.
    – Обдумай нашу пропозицію.
    – Ідіть з миром.
    
    Знову пролунав ріг. Так сурмили про наступ. Кіото приготувався, битва буде не довшою за сто ударів серця.
    
    Дев'яносто сім. Дев’яносто вісім. Дев’яносто дев’ять. Кіото зупинився й опустив меч. Земля під ногами була оповита тінями. Кіото знав, що вони ще повернуться. Але Воїн знову переможе, бо єдина битва, яку він міг програти, — це битва з самим собою. У нього всередині був спокій. Він дістав шовкову хустку й витер меч. На ній чудернацькими візерунками виступили червоні плями.
    
    Кіото закрив очі та прислухався. Птахи знову починали співати, з глибини лісу доносився рев тварин, тихо шуміла вода в струмках. Він обернувся й подивився на свій будинок, у якому спала, нічого не підозрюючи, Акайя. Кіото не оплакував полеглих на цьому полі бою, і тих, кому ще доведеться тут пасти. Жоден з них не був воїном, лише тінню. Вітер вже розносив запах їхньої крові та ехо останнього подиху. Ніхто не згадає цих безславних імен, ніхто їх не виплаче. Через кілька хвилин навколо шуміла лише м'яка зелена трава. Планета Мао прийняла їхню жертву. Кіото поклав в кишеню сніжно-білу хустку та пішов назад до будинку.
    `,
  },
};
