export const Story = ({ story }) => {
  return (
    <div className="page-container">
      <div className="story">
        <h3>{story.header}</h3>
        <div>{story.content}</div>
      </div>
    </div>
  );
};
