import { TITLES } from "./Content";

export const Contacts = ({ lang }) => {
    return (
      <div className="page-container">
        <div className="contacts">
        <p className="text">{TITLES[lang].contactsText}</p>
        </div>
      </div>
    );
  };