import { Link } from "react-router-dom";
import { TITLES } from "./Content";
import { getStorySlug } from "./methods";
import { Story1 } from "./stories/Story1";

import { Story2 } from "./stories/Story2";
import { Story3 } from "./stories/Story3";
import { Story4 } from "./stories/Story4";
import { Story5 } from "./stories/Story5";
import { Story6 } from "./stories/Story6";

export const allStories = [Story2, Story3, Story4, Story5, Story1, Story6];

export const Stories = ({ lang }) => {
  return (
    <div className="page-container">
      <div className="stories">
        <h3>{TITLES[lang].contentTitle}</h3>
        {allStories.map((story) => (
          <Link to={getStorySlug(story["EN"].header)}>
            {story[lang].header}
          </Link>
        ))}
      </div>
    </div>
  );
};
