export const Story6 = {
  EN: {
    header: "365 effective methods for every day",
    content:
      "I'm working on the translation! Be sure to stay tuned and subscribe to my Telegram channel for the latest updates on this matter.",
  },
  UA: {
    header: "365 ефективних методик на кожен день",
    content: `Було вже близько другої години ночі, але Ніка й досі не могла змусити себе піти спати. Перестрибуючи з однієї youtube передачі на іншу, вона лише краєм вуха ловила уривки діалогів з подкастів, рекламних роликів та всіляких ток-шоу. Нічого цікавого. Нічого нового. Чергове безсоння. А завтра вівторок і треба рано прокидатися на роботу. Знову буде поганий настрій, клювання носом за квартальними звітами, кілька чашок міцної кави. І ввечері, у її стомленому мозку, в черговий раз, промайне світла думка «пора щось змінювати». Життя здавалось одноманітним та прісним. На роботі вона з нетерпінням чекала, поки стрілки годинника нарешті зупиняться на шостій вечора, щоб піти додому. А вдома не знала чим себе зайняти. Мабуть, вона б вже давно звільнилася, якби не Андрій - їхній симпатичний системний адміністратор. Він був веселим і постійно жартував, як от учора, коли він постукав до кабінету і запитав, чи збирається вона сьогодні вийти з матриці. Ніка знову мимоволі посміхнулася цьому жарту. Та не дивлячись на взаємну симпатію, Андрій жодного разу навіть не натякав на побачення. 

      Несподівано її погляд зупинився на якійсь передачі. 
      
      – Це дуже цікаво, Антоне! – зваблива білявка з усіх сил намагалась відтворити бодай натяк зацікавленості на своєму прекрасному обличчі. – Невже ви дійсно допомагаєте людям змінити своє життя?
      
      – Так-так, – кивнув молодий хлопець, років двадцяти, – саме цим ми з командою займаємося.
      
      “Цікаво, що цей інфо-циган розкаже”, – подумала про себе Ніка.
      
      – Ми розкриваємо приховані таланти людини, – продовжував хлопець. – Цей шлях не швидкий. Він складається з кількох кроків, ми поступово ведемо людину до її справжнього призначення. Іноді під тиском суспільства або через думки оточення, наші справжні бажання та мрії сильно трансформуються або зовсім зникають. Завдання нашої команди – допомогти людині розкритися.
      
      Білявка повільно кивала у такт.
      
      – А з чого б ви порадили розпочати? Я впевнена, що нашим глядачам та гостям у студії це дуже цікаво!
      
      Камера повільно попливла по залу, знуджені обличчя людей одразу ж оживали й починали схвально кивати.
      
      – Для початку просто замовте мою нову книгу "365 ефективних методик на кожен день". Це своєрідний посібник, де описані маленькі поради та завдання, які точно вплинуть на ваше життя. Я гарантую вам перші позитивні результати вже за кілька тижнів. До речі, на книгу саме зараз приємна знижка.
      
      – Звучить, дуже цікаво!
      
      – Пфф, – фиркнула Ніка, вже збираючись клацнути на інше відео, як раптом хлопець з відео заговорив з нею.
      
      – Ніка, я думаю, тобі сподобається моя книга. Це ж саме те, чого ти хочеш, — сказав він, дивлячись крізь екран.
      
      Дівчина аж підскочила на ліжку. 
      
      – Ти нудьгуєш, погано спиш, майже не бачишся з друзями. Спробуй замовити мою книгу, можливо, вона змінить твоє життя?
      
      Після цих слів, дівчина швидко закрила ноутбук та включила світло. Їй стало моторошно. Як це може бути? Може їй це сниться? Але ні, вона не спить і точно в здоровому глузді. Точно?
      
      
      Увесь наступний день Ніка могла думати лише про те, що сталося. На обідній перерві за її столик підсів Андрій з новими жартами. Через кілька хвилин він помітив, що Ніка не в гуморі та делікатно запитав, що трапилось.
      
      – Ти не повіриш, – покачала дівчина головою.
      
      – О це так інтрига! То що сталося? – наполягав Андрій.
      
      – Ну гаразд, але обіцяй не насміхатися? 
      
      – Коли я насміхався з тебе? – здивовано вигукнув хлопець.
      
      – Учора вночі я дивилась якусь youtube передачу з якимось молодим гуру в психології, що написав книгу з порадами як змінити життя на краще. І от він повертається, дивиться в камеру і називає моє ім’я! Потім каже, мовляв, Ніка, ти нічого не робиш, купи мою книгу, вона тобі допоможе!
      
      Очі Андрія округлились, він з усіх сил стримував себе, щоб не засміятись. Йому це погано вдавалося, то ж Ніка сердито штовхнула його в плече.
      
      – Ти обіцяв!
      
      – Вибач, але я не можу, – хлопець почав вголос реготати. 
      
      – Це не смішно! Я ледь не зомліла. 
      
      – Ти щось приймала?
      
      – Я схожа на людину, яка щось приймає? Не треба було тобі розказувати.
      
      – Вибач! Я не втримався. Але це дуже прикольна історія. То що, ти замовила книгу?
      
      – Звісно ні, – трохи помовчавши Ніка запитала. – А з тобою ніколи такого не було?
      
      – На жаль, ні. Я зазвичай вночі міцно сплю.
      
      – Щастить тобі!
      
      – Хочеш давай разом зараз подивимось ту передачу?
      
      – Я не пам’ятаю як вона називалась.
      
      
      Увечері Ніка ходила довкола ноутбука і не наважувалась його відкрити. Через якийсь час вона подумала, що це все дуже смішно. Адже такого, нізащо і ніколи не могло бути. Тож вона впевнено відкрила комп’ютер, де на екрані застигло те саме відео. Дівчина натиснула на нього, але ведуча з гостем продовжували вести розмову про цю книгу, там і згадки не було про неї. Перемотавши та передивившись відео кілька разів від початку і до кінця, дівчина так і не знайшла того моменту, де цей Антон звертався до неї особисто. Виходить, їй все ж таки це наснилося? Зробивши собі чай, вона, як зазвичай, спокійно перемістилась до ліжка. Вечір швидко перейшов у ніч. На годиннику вже було пів на третю. Те, що трапилось вчора, не йшло з голови. Раптом Ніка вирішила пошукати якусь інформацію про цього Антона та його діяльність. Пошук не дав майже нічого, окрім ще кількох відео з ним. Дівчина клацнула на перше відео зі списку. Це було відео з подкасту про особистий розвиток. Хлопець так само нудно розповідав ведучому про свою нову книгу. Те саме лиття води у наївні вуха. 
      
      – Ніка, вибач, я не хотів тебе вчора налякати, – раптом почула вона знову своє ім’я. – Але я справді думаю, що моя книжка піде тобі на користь.
      
      – Як це можливо? – здивовано вигукнула дівчина, а потім дуже повільно вона взяла в руки телефон та включила запис відео. 
      
      Але Антон більше нічого не говорив їй особисто, продовжуючи розмову з ведучим. Як Ніка не намагалася знову знайти той самий фрагмент відео, їй це так і не вдалося. Заснула дівчина тільки під ранок. Десь о восьмій вона написала на роботу, що захворіла і проспала ще приблизно до дванадцятої. Її розбудив телефонний дзвінок. Це був Андрій, він дізнався, що Ніка захворіла та одразу ж подзвонив. 
      
      – Як ти? 
      
      – Усе норм, трохи застудилась, – Ніка ненавиділа брехати, але скажи вона правду, Андрій би точно подумав, що вона збожеволіла.
      
      Швидко попрощавшись, вона замовила ту книгу онлайн на найближче поштове відділення біля роботи.
      
      
      Сьогодні, вперше за багато місяців, Ніка бігла на роботу. Насправді на Нову пошту, щоб забрати свою книжку. Їй так кортіло дізнатися, що ж там. Чому ця книжка переслідує її. 
      
      З коробкою під рукою, Ніка наче вихор пронеслась по коридору, збиваючи усіх хто траплявся їй на шляху. Зручно вмостившись у своєму кріслі, дівчина дістала з коробки досить тоненьку книжечку. 
      
      – Це все? – розчаровано запитала вона саму себе. – Оцей зошит має змінити моє життя?
      
      Але вже через кілька секунд розчарування безслідно зникло. На першій же сторінці вона побачила своє ім’я.
      
      
      “Люба Ніка, я вірю, що ця книга стане тобі у пригоді. Тут ти знайдеш практичні поради на кожен день. Будь готова до змін, внутрішніх та зовнішніх. День перший. Поклич на побачення того, хто давно тобі подобається”. Сказати, що вона була здивована – нічого не сказати. Ледь перевівши подих, дівчина відклала книгу. Це точно якісь чари, чи магія.
      
      
      Один рік потому. 
      
      
      У невеличкому, але затишному готельному номері метушилась купа людей. Візажист, перукар, фотограф, подружки нареченої й звісно сама наречена. Усі були щасливі та веселі. Хтось вже тишком розливав шампанське у келихи. Ніка ж спостерігала за всіма з ванної кімнати. Сьогодні її молодша сестра виходила заміж. А ще сьогодні вона прочитає останню сторінку своєї дивовижної книги, яка перевернула її життя догори дриґом. Дівчина прикрила на хвилину двері та дістала зі своєї маленької сумочки той самий зошит, вже трохи потертий. 
      
      “День триста шістдесят п’ятий. Ніка, це наш прощальний день! І сьогодні у нас не буде ніяких порад, окрім маленького прохання, залишити свій відгук ось за цим посиланням. Не зупиняйся на досягнутому, це тільки початок! Попереду тебе чекає так багато усього.”
      
      – Це все? – сумно запитала Ніка й одразу ж засміялась, згадавши, що так само відреагувала, коли вперше побачила цей посібник. 
      
      У двері хтось тихенько постукав і через секунду в щілину заглянула Марійка.
      
      – Нікусь, тебе там Андрій шукає. 
      
      – Дякую! Біжу.
      
      На мить її погляд затримався у дзеркалі, дівчина знову щасливо посміхнулась та пішла шукати свого коханого.
      
      
      Сан Франциско, головний офіс компанії Crazy Magic Ads
      
      У просторій кімнаті за довжелезним столом зібралось близько двадцяти людей. Усі щось тихо обговорювали між собою. Біля великого екрана, на якому вже була підключена презентація, стояла жінка в стильному діловому костюмі. Вона трохи приглушила світло та перемкнула перший слайд.
      
      – Сьогодні ми підбиваємо підсумки першого року експериментального запуску нашої таргетної реклами згенерованої штучним інтелектом. У експерименті взяло участь дві тисячі людей з різних країн, різного віку та з різним рівнем прибутку. На цьому слайді ви можете побачити повноцінну вибірку. Продукт – книга з повсякденними порадами. Попри те, що автор книги невідомий і не має ніякого особистого бренду, двадцять три відсотки купили продукт після першого перегляду, ще сорок два відсотки після другого, і три відсотки після третього. Шість відсотків поскаржились на нашу рекламу, решта проігнорували. Як ми бачимо, сумарно продукт купили шістдесят вісім відсотків, що просто фантастично. І я вітаю вас з цим успіхом!
      
      Люди в кімнаті весело загули. Один молодий хлопець нерішуче підняв руку.
      
      – Так, Марк, кажи, – кивнула жінка.
      
      – Мені просто цікаво, а скільки людей дочитали до кінця посібник? Там останнє завдання було надіслати відгук, щоб ми могли порахувати. 
      
      – Гадки не маю, – байдуже стиснула плечима жінка. – Яка різниця? Нам важливо скільки людей його купили, а не скільки дочитали. 
      
      `,
  },
};
