import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { baseUrl } from "./api/imageService";
import { Evolving } from "./Evolving";

Modal.setAppElement("#root"); // This should be the root element of your React app

export const ImageGallery = ({ images, rows }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [imageClass, setImageClass] = useState("");
  const [imageSize, setImageSize] = useState(false);

  const galleryRef = useRef();

  const [containerRef, setContainerRef] = useState();

  const openModal = (image) => {
    const hightQualityImg = image.replace("thumbnail_", "gq_");
    setSelectedImage(hightQualityImg);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
    setImageClass("");
  };

  const handleClickOutside = (e) => {
    if (e.target.classList.contains("overlay")) {
      closeModal();
    }
  };

  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    if (naturalWidth > naturalHeight) {
      setImageClass("modal-image landscape");
    } else {
      setImageClass("modal-image portrait");
    }
    e.target.classList.add("loaded");
  };

  useEffect(() => {
    const updateImageSize = () => {
      if (containerRef !== undefined) {
        const gaps = 6;
        const newSize = Math.floor((containerRef.clientHeight - gaps) / rows);
        setImageSize(newSize);
      }
    };

    updateImageSize();
    window.addEventListener("resize", updateImageSize, { passive: false });

    return () => {
      window.removeEventListener("resize", updateImageSize, { passive: false });
    };
  }, [rows, containerRef]);

  useEffect(() => {
    const useVerticalScroll = (event) => {
      if (event.deltaY !== 0) {
        event.preventDefault();
        galleryRef.current.scrollLeft += event.deltaY;
      }
    };

    document.addEventListener("wheel", useVerticalScroll, { passive: false });
    return () => {
      document.removeEventListener("wheel", useVerticalScroll, {
        passive: false,
      });
    };
  }, []);

  return (
    <div
      className="page-container-gallery"
      ref={(node) => setContainerRef(node)}
    >
      <div className="horizontal-gallery-container">
        <div className="horizontal-gallery" ref={galleryRef}>
          {images.map((colorGroup, colorIndex) => (
            <div key={colorIndex} className="color-group">
              {colorGroup.map((image, imageIndex) => (
                <div
                  key={imageIndex}
                  className="thumbnail-container"
                  style={{ width: imageSize, height: imageSize }}
                >
                  <img
                    src={image}
                    alt={`flower ${colorIndex}-${imageIndex}`}
                    className="thumbnail"
                    style={{ width: imageSize, height: imageSize }}
                    onClick={() => openModal(image)}
                    onLoad={handleImageLoad}
                  />
                </div>
              ))}
            </div>
          ))}
          <Modal
            isOpen={modalIsOpen}
            className="modal"
            overlayClassName="overlay"
            shouldCloseOnOverlayClick={true}
            onRequestClose={handleClickOutside}
            shouldCloseOnEsc={true}
          >
            {selectedImage && (
              <img
                src={selectedImage}
                alt="Selected"
                className={imageClass}
                onLoad={handleImageLoad}
                onClick={closeModal}
              />
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};
